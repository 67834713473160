import Layout from '@/components/layouts/Layout'
import { ContainerNarrow } from '@/components/styled/Container'
import { H1, H2, LongFormContent } from '@/components/styled/Typography'
import React from 'react'

export default function AmazonDataHandlingPolicy(): JSX.Element {
  return (
    <Layout LayoutContainer={ContainerNarrow}>
      <LongFormContent>
        <H1>Amazon Data Protection and Handling Policy</H1>
        <H2>Network Protection</H2>
        <ul>
          <li>
            ICHIGO developers utilize a network firewall to deny access to
            non-whitelisted IP addresses. Access is restricted only to approved
            users.
          </li>
          <li>
            ICHIGO developers are assigned unique accounts and keys to access
            Amazon Information, and cannot create or use generic, shared,
            default login credentials or user accounts. All access/authorization
            is controlled by ICHIGO&rsquo;s Engineering Manager.
          </li>
          <li>
            ICHIGO implements fine-grained access controls to ensure that at
            only the required user accounts can access Amazon Information,
            following the principle of least privilege. Access to Amazon
            Information is granted to specific users on a “need-to-know” basis.
          </li>
          <li>
            ICHIGO&rsquo;s Engineering Manager reviews the authorized list of
            people and services with access to Amazon Information on a regular
            basis, and removes accounts that no longer require access.&nbsp;
          </li>
          <li>
            ICHIGO staff are restricted from storing Amazon data on personal
            devices.&nbsp;
          </li>
          <li>
            ICHIGO developers have monitoring in place to detect anomalous usage
            patterns and login attempts, and disables accounts with access to
            Amazon Information as needed.
          </li>
          <li>
            ICHIGO developers enforce TLS and/or SSH encryption for all Amazon
            Information in transit within, but not limited to our network or
            between hosts. This is applied on all applicable endpoints and
            communication channels where Amazon Information may be sent.
          </li>
        </ul>
        <H2>Data Retention and Recovery</H2>
        <ul>
          <li>
            ICHIGO retains Amazon PII only for the purpose of, and as long as is
            necessary to fulfill orders, but no longer than 30 days after order
            shipment or to calculate/remit taxes. Any Amazon Information
            required by law to be retained for tax or similar regulatory
            purposes is archived and stored as a &ldquo;cold&rdquo; backup which
            is not available for immediate or interactive use. All backups use
            secure AWS cloud storage, and all archived data is encrypted. In the
            event that PII is lost, ICHIGO will be able to recover all PII lost.
          </li>
        </ul>
        <H2>Data Governance and Asset Management</H2>
        <ul>
          <li>
            ICHIGO developers create, document, and abide by privacy and data
            handling policies for their Applications or services which govern
            the appropriate conduct and technical controls to be applied in
            managing and protecting information assets.
          </li>
          <li>
            ICHIGO developers maintain a record of data processing activities
            such as specific data fields and how they are collected, processed,
            stored, used, shared, and disposed for all Amazon PII to establish
            accountability and compliance with regulations.
          </li>
          <li>
            ICHIGO developers have established and abide by the ICHIGO privacy
            policy for customer consent and data rights to access, rectify,
            erase, or stop sharing/processing their information where applicable
            or required by data privacy regulation.
          </li>
          <li>
            ICHIGO developers keep inventory of software and physical assets
            such as, but not limited to computers and mobile device with access
            to Amazon PII, and update regularly. ICHIGO does not store Amazon
            PII on removable media, personal devices, or unsecured public cloud
            applications unless the data is encrypted using AES-256 encryption
            keys or equivalent.
          </li>
          <li>
            ICHIGO developers securely dispose of any printed documents
            containing PII.
          </li>
        </ul>
        <H2>Secure Coding Practices</H2>
        <ul>
          <li>
            ICHIGO developers encrypt all PII at rest using AES-256 or stronger,
            with understanding of industry best practice standards. All
            cryptographic materials used for encryption of Amazon PII at rest,
            including, but not limited to encryption/decryption keys and
            cryptographic capabilities, are only accessible to ICHIGO&rsquo;s
            processes and services.
          </li>
          <li>
            ICHIGO developers follow industry best practices for secure coding,
            including storing sensitive credentials separately from code
            repositories, and maintaining isolated test and production
            environments.
          </li>
        </ul>
        <H2>Logging and Monitoring</H2>
        <ul>
          <li>
            ICHIGO developers gather logs to detect security-related events
            including, but not limited to access and authorization, intrusion
            attempts or configuration changes to their Applications and systems.
            This logging mechanism is in force on all channels including, but
            not limited to service APIs, storage-layer APIs or administrative
            dashboards providing access to Amazon Information. All logs have, in
            force, access controls to prevent any unauthorized access and
            tampering throughout their lifecycle. Logs themselves do not contain
            Amazon PII and are retained for at least 90 days for reference in
            the event of a Security Incident.
          </li>
          <li>
            ICHIGO developers have, in force, mechanisms to monitor the logs and
            all system activities to trigger investigative alarms on suspicious
            actions, including, but not limited to, multiple unauthorized calls,
            unexpected request rate and data retrieval volume, or access to
            canary data records.&nbsp;
          </li>
          <li>
            ICHIGO developers perform an investigation when monitoring alarms
            are triggered, as documented in ICHIGO&rsquo;s Incident Response
            Plan.
          </li>
          <li>
            ICHIGO developers perform both pre-release code vulnerability
            scanning and manual vulnerability testing on systems including, but
            not limited to, all systems which deal with Amazon Information, and
            take appropriate remediation steps for any discovered
            vulnerabilities. ICHIGO&rsquo;s Engineering Manager controls access
            to testing, verification and approval for all changes related to the
            storage of Amazon Information.
          </li>
        </ul>
        <H2>Audit</H2>
        <ul>
          <li>
            ICHIGO developers maintain all appropriate books and records
            reasonably required to verify compliance with the Amazon Acceptable
            Use Policy, Amazon Data Protection Policy, and Amazon Marketplace
            Developer Agreement during the period of agreement and for 12 months
            thereafter.&nbsp;
          </li>
          <li>
            Upon Amazon&rsquo;s written request, ICHIGO developers will certify
            in writing to Amazon that they are in compliance with these
            policies.
          </li>
          <li>
            ICHIGO developers will cooperate with Amazon or Amazon&rsquo;s
            auditor in connection with any audit requested by Amazon. If the
            audit reveals deficiencies, breaches, and/or failures to comply with
            Amazon or Amazon&rsquo;s auditor&rsquo;s terms, conditions, or
            policies, ICHIGO, at its sole cost and expense, take all actions
            necessary to remediate those deficiencies within an agreed-upon
            timeframe.
          </li>
        </ul>
      </LongFormContent>
    </Layout>
  )
}
